<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card class="rOrderNotice-dlg-bg" flat>
            <q-card-section class="column justify-around" style="min-height:270px;">
                <q-select outlined class="col" v-model="NoticeType" :options="NoticeTypeOptions" label="请选择通知类型：" />

                <q-input filled class="col-auto" type="textarea" v-model="NoticeContent" label="通知内容：" lazy-rules
                    :rules="[
    val => val !== null && val !== '' || '请输入通知内容']" />
            </q-card-section>
            <q-separator />

            <q-card-actions align="right">
                <q-btn flat color="primary" @click="onSubmit" label="确定" />
                <q-btn v-close-popup flat color="primary" @click="onReset" label="取消" />
            </q-card-actions>
        </q-card>

        <!-- <q-card class="my-card">
            <q-img src="https://cdn.quasar.dev/img/chicken-salad.jpg" />
            <q-card-section>
                <q-btn fab color="primary" icon="place" class="absolute"
                    style="top: 0; right: 12px; transform: translateY(-50%);" />
                <div class="row no-wrap items-center">
                    <div class="col text-h6 ellipsis">
                        Cafe Basilico
                    </div>
                    <div class="col-auto text-grey text-caption q-pt-md row no-wrap items-center">
                        <q-icon name="place" />
                        250 ft
                    </div>
                </div>

                <q-rating v-model="stars" :max="5" size="32px" />
            </q-card-section>

            <q-card-section class="q-pt-none">
                <div class="text-subtitle1">
                    $・Italian, Cafe
                </div>
                <div class="text-caption text-grey">
                    Small plates, salads & sandwiches in an intimate setting.
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn v-close-popup flat color="primary" label="Reserve" />
                <q-btn v-close-popup flat color="primary" round icon="event" />
            </q-card-actions>
    </q-card> -->
    </q-dialog>
</template>
  
<script>
import { useQuasar } from 'quasar'
import { mapActions, mapState } from "vuex";


export default {
    components: {
    },
    computed: {
        ...mapState("PdmRecvOrder", [
            "Loading", "NoticeTypeOptions"
        ]),
    },
    props: {
        OrderInfo: {
            default: null,
            type: Object
        },
        OrderIDs: {
            default: function () { return [] },
            type: Array
        },
        IsEdit: {
            default: 0,
            type: Number
        }
    },
    emits: [
        'ok', 'hide'
    ],
    data() {
        return {
            $q: useQuasar(),
            NoticeType: null,
            NoticeContent: "",
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions("PdmRecvOrder", [
            "actSendNotices"
        ]),
        show() {
            this.$refs.dialog.show()
        },
        hide() {
            this.$refs.dialog.hide()
        },
        onDialogHide() {
            // QDialog发出“hide”事件时
            // 需要发出
            this.$emit('hide')
        },
        onCancelClick() {
            // 我们只需要隐藏对话框
            this.hide()
        },
        onOKClick() {
            this.$emit('ok', this.OrderIDs)
            this.hide()
        },
        onSubmit() {
            if (this.NoticeType == null || this.NoticeType.value < 1) {
                this.$q.notify({
                    color: 'red-5',
                    textColor: 'white',
                    icon: 'warning',
                    message: '请选择通知类型！'
                })
            }
            else if (this.NoticeContent == null || this.NoticeContent.length < 1) {
                this.$q.notify({
                    color: 'red-5',
                    textColor: 'white',
                    icon: 'warning',
                    message: '请输入通知内容！'
                })
            }
            else {
                console.log("发通知！")

                this.actSendNotices({
                    HospID: this.$store.getters["appUser/hospID"],
                    CUser: this.$store.getters["appUser/userID"],
                    OrderIDs: this.OrderIDs,
                    NoticeType: this.NoticeType.value,
                    NoticeContent: this.NoticeContent
                }).then((res) => {
                    if (res.Code == 0) {
                        this.msg.success(res.Message)
                        this.hide()
                    }
                });

            }
        },

        onReset() {
            this.NoticeType = null
            this.NoticeContent = ""
        }


    },
};
</script>
  
<style scoped>
.rOrderNotice-dlg-bg {
    position: absolute;
    width: 40%;
    height: 40%x;
    min-width: 300px;
    min-height: 200px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
}

.rOrderdetail-dlg-bg .top-orderInfo {
    height: 85px;
    border-top: 1px solid rgba(0, 0, 0, 0.156);
    border-bottom: 1px solid rgba(0, 0, 0, 0.156);
    margin-bottom: 8px;
    padding: 12px 20px;
    color: #3b3737;
}

.rOrderdetail-dlg-bg .topleftblue {
    background-color: var(--q-primary);
    width: 5px;
    height: 20px;
}

.rOrderdetail-dlg-bg .sel_cf {
    background-color: var(--q-primary);
    color: #FFFFFF;
}
</style>
  
  