<template>
    <q-card flat>
        <q-card-section class="q-pa-sm">
            <div class="row q-gutter-sm q-mb-sm">
                <div class="row items-center">
                    <div class="ro-topleftblue q-mr-md"></div>诊疗订单
                </div>
                <q-space />
                <q-input outlined class="col-1" dense v-model="PatnOrCertNo" label="姓名/身份证号" color="primary" />
                <q-input outlined class="col-2" dense v-model="OrderID" label="订单号" color="primary" />
                <q-input outlined class="col-1" dense v-model="TelPhone" label="联系电话" color="primary" />
                <q-input outlined class="col-2" clearable clear-icon="close" dense v-model="BegnDate" mask="date"
                    label="开始时间">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-date v-model="BegnDate">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="关闭" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <q-input outlined class="col-2" clearable clear-icon="close" dense v-model="EndDate" mask="date"
                    label="结束时间">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-date v-model="EndDate">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="关闭" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>

                <q-btn color="primary" icon="search" @click="onLoadData" :loading="Loading" style="height: 38px;">
                    查询
                </q-btn>
                <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
                下载
              </q-btn>
              <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="Loading" @click="onExportData">导出</q-btn> -->
                <!-- <q-btn color="primary" style="height: 38px" icon="note_add" :loading="Loading"
                    @click="onSendGoods">发货</q-btn> -->
            </div>
            <vxe-grid v-bind="gridOptions" :cell-class-name="cellClassName" :sort-config="{ multiple: true }"
                :custom-config="{ storage: true }" id="custom-config" :export-config="gridOptions.tableExport"
                :height="$store.getters.maxPageHeight - 140" :loading="Loading" highlight-current-row size="mini">
                <template #toolbar_buttons id="custom-config"> </template>
                <template #patnName="{ row }">
                    <q-btn flat rounded color="primary" :label="row.PATIENT_NAME" @click="openDetail(row)" />
                </template>
                <template #operate="{ row }">
                    <vxe-button flat color="primary" @click="onSendNotice(row)">发通知</vxe-button>
                    <vxe-button flat color="negative" @click="onDelete(row)">作废</vxe-button>
                </template>
            </vxe-grid>
            <vxe-pager perfect size="mini" :page-sizes="tablePage.pageSizes"
                v-model:current-page="tablePage.currentPage" v-model:page-size="tablePage.pageSize"
                :total="tablePage.totalResult" :layouts="[
    'PrevPage',
    'JumpNumber',
    'NextPage',
    'FullJump',
    'Sizes',
    'Total',
]" @page-change="handlePageChange">
            </vxe-pager>
        </q-card-section>
    </q-card>

    <q-dialog v-model="pdmOrdershowDlg" persistent>

    </q-dialog>



</template>
  
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { useQuasar } from 'quasar'
import DetailView from "./receiveDetailView.vue";
import SendNoticeView from "./receiveNotice.vue";
export default {
    computed: {
        ...mapState("PdmRecvOrder", [
            "Loading",
            "PdmOrderShowDlg",
            "HospHisCode"
        ]),
        ...mapGetters("appUser", ["authHosps"]),


        pdmOrdershowDlg: {
            get() {
                return this.PdmOrderShowDlg;
            },
            set(val) {
                console.log(val);
                this.$store.commit("PdmRecvOrder/setPdmOrderShowDlg", val);
            },
        },
    },
    data() {
        return {
            Cur_HospID: "", //当前机构备用 
            sendMsgDlg: false,
            PatnOrCertNo: "",
            OrderID: "",
            FilterString: "",
            TelPhone: "",
            BegnDate: "",
            EndDate: "",
            $q: useQuasar(),
            tablePage: {
                currentPage: 1,
                pageSize: 20,
                totalResult: 0,
                pageSizes: [15, 20, 50, 100, 5000],
            },
            gridOptions: {
                border: true,
                resizable: true,
                showOverflow: true,
                highlightHoverRow: true,
                align: "left",
                toolbarConfig: {
                    perfect: true,
                    print: true,
                    zoom: true,
                    custom: true,
                    export: true,
                    slots: {
                        // 自定义工具栏模板
                        buttons: "toolbar_buttons",
                    },
                },
                tableExport: {},
                columns: [
                    { type: "seq", width: 50, align: "center" },
                    {
                        // field: "PATIENT_NAME",
                        title: "姓名",
                        sortable: true,
                        align: "left",
                        slots: { default: 'patnName' },
                        width: 120,
                    },
                    {
                        field: "PATIENT_NUMBER",
                        title: "身份证号",
                        sortable: true,
                        align: "left",
                        width: 180,
                    },
                    {
                        field: "OrderID",
                        title: "订单号",
                        sortable: true,
                        align: "left",
                        width: 160,
                    },
                    {
                        field: "TelPhone",
                        title: "联系电话",
                        sortable: true,
                        align: "left",
                        width: 160,
                    },
                    {
                        field: "CFCount",
                        title: "处方数量",
                        sortable: true,
                        align: "left",
                        width: 100,
                    },
                    {
                        field: "GoodsCount",
                        title: "购药总数(件)",
                        sortable: true,
                        align: "left",
                        width: 110,
                    },
                    {
                        field: "TotalAmt",
                        title: "总金额",
                        sortable: true,
                        align: "left",
                        width: 120,
                    },
                    {
                        field: "IsAuditName",
                        title: "审核状态",
                        sortable: true,
                        align: "center",
                        width: 100,
                    },
                    {
                        field: "AddDate",
                        title: "下单时间",
                        sortable: true,
                        align: "center",
                        width: 140,
                    },
                    {
                        field: "UserName",
                        title: "收货人",
                        sortable: true,
                        align: "left",
                        width: 100,
                    },
                    {
                        field: "Address",
                        title: "收货人地址",
                        sortable: true,
                        align: "left",
                        width: 200,
                    },

                    {
                        field: "IsDeliveryName",
                        title: "是否发货",
                        sortable: true,
                        align: "center",
                        width: 100,
                    },

                    {
                        title: "操作",
                        width: 200,
                        slots: { default: "operate" },
                        align: "center",
                        fixed: "right",
                    }

                ],
                // data: this.PdmOrderList,
                data: null,
            },
        };
    },
    mounted() {
        this.onGetHospHisCode();
    },
    methods: {
        ...mapActions("PdmRecvOrder", [
            "actGetOrderListByPage",
            "actGetMZCFList",
            "actReceivePatn",
            "actSetCurHosp"
        ]),
        ...mapActions("PdmOrder", [
            "actDeletePdmOrder"
        ]),
        ...mapActions("appUser", [
            "actSetCurrentHospID",
        ]),
        onGetHospHisCode() {
            const hospHisCode = this.$route.query.HospHisCode

            console.log("获取到机构编码：" + hospHisCode)
            if (hospHisCode != null && hospHisCode.length > 0) {
                this.actSetCurHosp(hospHisCode)
            }
            else {
                if (this.HospHisCode == null || this.hospHisCode.length < 1) {
                    this.msg.error("获取机构信息失败!");
                    return;
                }
            }
            this.onLoadData();
        },
        onLoadData() {
            this.actGetOrderListByPage({
                // HospID: 0,
                HospHisCode: this.HospHisCode,
                CUser: this.$store.getters["appUser/userID"],
                OrderID: this.OrderID,
                FilterString: this.FilterString,
                PatnOrCertNo: this.PatnOrCertNo,
                TelPhone: this.TelPhone,
                BegnDate: this.BegnDate,
                EndDate: this.EndDate,
                PageIndex: this.tablePage.currentPage,
                pageSize: this.tablePage.pageSize,
            })
                .then((res) => {
                    if (res.Code == 0) {
                        this.actSetCurrentHospID(res.OtherData.HospID);
                        this.Cur_HospID = res.OtherData.HospID
                        this.gridOptions.data = res.Data;
                        this.tablePage.totalResult = res.Count;
                    }
                })
                .finally(() => {
                    this.$store.commit("PdmRecvOrder/setLoading", false);
                });
        },
        cellClassName({ column }) {
            if (column.property === 'TotalAmt') {
                return 'text-red'
            }
        },
        openDetail(row) {
            console.log("查看详情row：" + JSON.stringify(row))
            this.$q.dialog({
                component: DetailView,
                componentProps: {
                    OrderInfo: row,
                    OrderID: row.OrderID,
                },
                parent: this,
                position: 'right'
            }).onOk((itemIDs) => {
                console.log("关闭" + JSON.stringify(itemIDs))
            }).hide(() => {
                console.log('hided')
            }).onCancel(() => {
                console.log('>>>> Cancel')
            }).onDismiss(() => {
                console.log('>>>> Dismiss')
            })
        },
        onReceivePatn(row) {
            console.log("医生接诊：" + JSON.stringify(row))
            this.actReceivePatn({
                HospID: this.Cur_HospID,
                CUser: this.$store.getters["appUser/userID"],
                OrderID: row.OrderID
            }).then((res) => {
                if (res.Code == 0) {
                    this.msg.success("开方成功！")
                }
            });
        },
        onSendNotice(row) {
            console.log("发通知" + JSON.stringify(row))
            var orderList = []
            orderList.push(row.OrderID)
            this.$q.dialog({
                component: SendNoticeView,
                componentProps: {
                    OrderIDs: orderList,
                },
                parent: this,
                position: 'right'
            }).onOk((itemIDs) => {
                console.log("关闭" + JSON.stringify(itemIDs))
            }).hide(() => {
                console.log('hided')
            }).onCancel(() => {
                console.log('>>>> Cancel')
            }).onDismiss(() => {
                console.log('>>>> Dismiss')
            })
        },
        onDelete(row) {
            this.actDeletePdmOrder({
                HospID: this.Cur_HospID,
                OrderID: row.OrderID
            }).then(res => {
                if (res.Code != 0) {
                    this.msg.error("操作失败!" + res.Message);
                }
                else {
                    this.onLoadData()
                    this.msg.success("删除成功!");
                }
            })
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.onLoadData();
        },
    },
};
</script>
<style scoped>
.ro-topleftblue {
    background-color: var(--q-primary);
    width: 5px;
    height: 20px;
}
</style>